import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ImageMO53 = () => {

  return (
    <StaticImage
      src="../../images/JPG-MALE-LOOK005-3.jpg"
      
      alt="Reserved avatar"
    />
  )
}

export default ImageMO53
