import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ImageMO33 = () => {

  return (
    <StaticImage
      src="../../images/JPG-MALE-LOOK003-3.jpg"
      
      alt="Reserved avatar"
    />
  )
}

export default ImageMO33
