import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ImageMO66 = () => {

  return (
    <StaticImage
      src="../../images/JPG-MALE-LOOK006-6.jpg"
      
      alt="Reserved avatar"
    />
  )
}

export default ImageMO66
