import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ImageMO62 = () => {

  return (
    <StaticImage
      src="../../images/JPG-MALE-LOOK006-2.jpg"
      
      alt="Reserved avatar"
    />
  )
}

export default ImageMO62
