import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const ImageMO36 = () => {

  return (
    <StaticImage
      src="../../images/JPG-MALE-LOOK003-6.jpg"
      
      alt="Reserved avatar"
    />
  )
}

export default ImageMO36
